sup {
  top: -.7em;
  font-size: 15px; }

.navbar-default {
  height: 100px;
  margin-bottom: 0;
  border: 0;
  background-color: $white;

  .container {
    padding-right: 0; }

  .navbar-nav {
    > .active {
      &:last-of-type {
        background-color: darken($green, 5%); }

      > a {
        background-color: transparent;

        &:hover {
          background-color: transparent; } } }


    > li {
      display: inline-block;
      margin-left: -4px;
      float: none;
      vertical-align: middle;

      &:after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 22px;
        transform: translateY(-50%);
        background: $gainsboro;
        content: ''; }

      &:nth-of-type(2) {
        a {
          padding-left: 27px; } }

      &:nth-last-of-type(2),
      &:last-of-type {
        &:after {
          display: none; } }

      &:last-of-type {
        border-radius: 4px;
        background-color: $green;

        &:hover {
          background-color: darken($green, 5%); }

        a {
          padding: 7px 31px 7px 32px;
          color: $white;

          &.active {
            color: $white; } } }

      > a {
        margin: 0;
        padding: 8px 30px;
        color: $nobel;
        font-size: 10px;
        line-height: 11px;
        text-transform: uppercase;

        &.active {
          color: $night-rider; } } } }

  &:last-child {
    border-right: 0; } }

.navbar-collapse {
  padding: {
    right: 0;
    left: 0; }
  background-color: $white;
  text-align: center; }

a {
  &.navbar-brand {
    height: 100px; } }

ul {
  &.navbar-nav {
    margin-top: 29px; }

  &.descargas {
    color: $cobalt;
    font-family: Ubuntu, sans-serif;

    li {
      margin: 15px 0;

      span {
        color: $black; }

      a {
        margin: 0 10px; } } }

  &.como {
    padding-left: 15px;

    li {
      margin: 10px 0;
      font-family: Ubuntu, sans-serif; } } }

.navbar-toggle {
  margin: 30px 10px 10px; }

.slide {
  width: 100%;
  height: 341px;
  background: {
    image: url('../img/cabezal-1.jpg');
    size: cover; } }


.container-title {
  padding-top: 20px;
  background-color: $cobalt;

  ul {
    display: block;
    width: 64%;
    margin: 20px auto;

    li {
      color: $white;
      font-family: Ubuntu, sans-serif;
      font-size: 20px; } }

  h1 {
    padding-top: 10px;
    color: $white;
    font: {
      family: Ubuntu, sans-serif;
      size: 30px; }
    text-align: center; }

  p {
    width: 61%;
    margin: 0 auto;
    color: $white;
    font: {
      family: Ubuntu, sans-serif;
      size: 20px; }
    text-align: justify;

    span {
      font-size: 30px; } } }

//////////////////
// CAJAS HOME
//////////////////

.item-box {
  height: 200px;
  background-color: $dim-gray;

  &:nth-child(2n+1) {
    background-color: $matterhorn; }

  a {
    display: block;
    width: 100%;
    height: 100%;
    background: {
      size: 79px;
      repeat: no-repeat; }
    color: $white;
    font-weight: bold;
    text: {
      transform: uppercase;
      align: center;
      decoration: none; }
    word-wrap: break-word;

    span {
      font-weight: normal; } } }

.icono-1 {
  padding-top: 40px;
  background: {
    position: center center;
    size: contain !important; //sass-lint:disable-line no-important
    image: url('../img/1.gif'); }
  font-size: 18px;
  line-height: 22px;

  span {
    font-size: 12px; } }

.icono-2 {
  padding-top: 75px;
  background: {
    position: center center;
    size: contain !important; //sass-lint:disable-line no-important
    image: url('../img/2.gif'); } }


.icono-3 {
  padding-top: 40px;
  background: {
    position: center center;
    size: contain !important; //sass-lint:disable-line no-important
    image: url('../img/3.gif'); }
  font-size: 20px;
  line-height: 22px;

  span {
    font-size: 12px; } }

.icono-4 {
  padding-top: 40px;
  background: {
    position: center center;
    size: contain !important; //sass-lint:disable-line no-important
    image: url('../img/4.gif'); }
  font-size: 18px !important; //sass-lint:disable-line no-important
  line-height: 22px; }

.icono-5 {
  padding-top: 40px;
  background: {
    position: center center;
    size: contain !important; //sass-lint:disable-line no-important
    image: url('../img/5.gif'); }
  font-size: 18px !important; //sass-lint:disable-line no-important
  line-height: 22px; }

.icono-6 {
  padding-top: 40px;
  background: {
    position: center center;
    size: contain !important; //sass-lint:disable-line no-important
    image: url('../img/6.gif'); }
  font-size: 18px;
  line-height: 22px;

  span {
    font-size: 12px; } }

.footer {
  padding: 13px 0;
  background-color: $white;

  a {
    display: inline-block; }

  p {
    margin-bottom: 0;
    padding-top: 2px;
    color: $dark-cerulean;
    font-size: 10px;

    &.-bigger-space {
      padding-top: 10px; } } }

//////////////////
// COMO SE USA
//////////////////

.container-comoseusa {
  height: 700px;
  background: {
    size: cover;
    image: url('../img/fondo_video.jpg'); }


  h1 {
    margin-top: 150px;
    color: $white;
    font-family: Ubuntu, sans-serif;
    text: {
      transform: uppercase;
      align: center; } }


  .player {
    width: 500px;
    height: 375px;
    margin: 20px auto;

    video {
      width: 100%;
      height: 100%; } } }

//////////////////
// INTERIOR GENERICA
//////////////////

.banner-interior {
  width: 100%;
  height: 341px;
  background: {
    position: center center;
    size: cover; } }


.titulo-interior {
  display: block;
  width: 100%;
  margin-top: 0;
  background-color: $cobalt;
  color: $white;
  font: {
    family: Ubuntu, sans-serif;
    size: 22px; }
  line-height: 55px;
  text: {
    transform: uppercase;
    align: center; } }


p {
  &.referencia {
    font: {
      size: 12px;
      style: italic; } }


  &.texto-interior {
    font-family: Ubuntu, sans-serif;

    sup {
      font-size: 10px; } } }

.banner-como {
  background-image: url('../img/header_como.jpg'); }

.banner-seguridad {
  background-image: url('../img/header_seguridad.jpg'); }

.banner-guardar {
  background-image: url('../img/header_donde_se_guarda.jpg'); }

.banner-accion {
  background-image: url('../img/header_modo_de_accion.jpg'); }

.banner-donde {
  background-image: url('../img/header_donde_solicitarlo.jpg'); }

.banner-informacion {
  background-image: url('../img/header_informacion.jpg'); }

.boton-telefono-1,
.boton-telefono-2 {
  padding: 10px 0;
  color: $grey;
  font: {
    family: Ubuntu, sans-serif;
    weight: bold; }
  line-height: 40px;

  img {
    margin-right: 10px; } }

h3 {
  &.urgente {
    color: $cobalt;
    font-family: Ubuntu, sans-serif;
    font-weight: bold; } }

.solo-santiago {
  font-family: Ubuntu, sans-serif;

  &.-bigger-bottom-space {
    margin-bottom: 15px; } }

h1 {
  &.titulo-interior {
    span {
      font-size: 14px; } } }

.mododeaccion {
  video {
    width: 100%; } }

h2 {
  &.titulo-descargas {
    padding-left: 45px;
    background: {
      image: url('../img/icono_archivo_descarga.gif');
      repeat: no-repeat; }
    color: $cobalt;
    font: {
      family: Ubuntu, sans-serif;
      size: 24px;
      weight: bold; }
    line-height: 38px; } }

.chile-map {
  max-width: 712px;
  min-height: 575px; }

.form {
  margin: 22px 5px 20px;

  label {
    @include icon(pointer);
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      color: $eucalyptus;
      font-size: 23px; } }

  input {
    width: 100%;
    padding: 8px 20px 9px 38px;
    border: {
      width: 1px;
      color: $silver;
      style: solid;
      radius: 20px; }
    color: $grey;
    font-weight: normal; } }

.map-section {
  margin-top: 12px; }

.update {
  margin: {
    top: 15px;
    bottom: 9px; }
  font: {
    size: 16px;
    weight: 500; } }


.legend,
.note {
  font-size: 12px;
  line-height: 18px; }

.note {
  margin: 18px 0 29px; }

.icons {
  display: block;
  margin-bottom: 12px;
  color: $matterhorn;
  font-weight: bold;

  &:hover {
    color: $matterhorn; }

  img {
    margin-right: 6px; } }

.small-heading {
  margin: {
    bottom: 5px;
    left: 36px; }
  font-weight: bold; }

.urgente {
  &.-big-top-space {
    margin-top: 56px; }

  &.-map-heading {
    font-weight: 500; }

  &.-small-top-space {
    margin: {
      top: 31px;
      bottom: 14px; } } }


.img-responsive {
  width: 100%; }

.map-legend {
  &:last-of-type {
    .map-legend-item {
      padding: 0;
      border: 0; } } }

.map-legend-item {
  padding-bottom: 15px;
  border-bottom: 1px solid $silver; }


