// sass-lint:disable indentation

//////////////////
// IPHONE 6 in portrait & landscape
//////////////////

@media (min-device-width: 375px) and (max-device-width: 667px) {
  .slide {
    height: 180px; }

  .container-title {
    h1 {
      padding-top: 5px;
      font-size: 20px; }

    p {
      width: 100%;
      font-size: 15px; }

    ul {
      width: 100%;
      padding-left: 15px;

      li {
        font-size: 15px; } } }

  .item-box {
    height: 125px;

    a {
      line-height: 15px;

      span {
        font-weight: normal; } } }

  .icono-1 {
    padding-top: 40px;
    font-size: 12px;
    line-height: 22px;

    span {
      font-size: 10px; } }

  .icono-2 {
    padding-top: 43px; }

  .icono-3 {
    padding-top: 35px;
    font-size: 12px;
    line-height: 22px;

    span {
      font-size: 10px; } }

  .icono-4,
  .icono-5 {
    padding-top: 45px;
    font-size: 12px !important; //sass-lint:disable-line no-important
    line-height: 22px; }

  .icono-6 {
    padding-top: 45px;
    font-size: 12px;
    line-height: 22px;

    span {
      font-size: 12px; } }

  .navbar-default {
    .navbar-nav {
      > li {
        > a {
          font-size: 15px;
          line-height: 25px; } } }

    .navbar-collapse {
      position: relative;
      z-index: 999; } }

  .footer {
    a {
      margin: 15px auto 0; }

    p {
      text-align: center; } }

  .container-comoseusa {
    height: 450px;

    h1 {
      margin-top: 50px; }

    .player {
      width: 90%;
      height: 175px;
      margin: 20px auto; } } }

//////////////////
// IPHONE 5 & 5S in portrait & landscape
//////////////////

@media (min-device-width: 320px) and (max-device-width: 568px) {
  .slide {
    height: 180px; }

  .container-title {
    h1 {
      padding-top: 5px;
      font-size: 20px; }

    p {
      width: 100%;
      font-size: 15px; }

    ul {
      width: 100%;
      padding-left: 15px;

      li {
        font-size: 15px; } } }

  .item-box {
    height: 125px;

    a {
      line-height: 15px;

      span {
        font-weight: normal; } } }

  .icono-1 {
    padding-top: 40px;
    font-size: 12px;
    line-height: 22px;

    span {
      font-size: 10px; } }

  .icono-2 {
    padding-top: 43px; }

  .icono-3 {
    padding-top: 35px;
    font-size: 12px;
    line-height: 22px;

    span {
      font-size: 10px; } }

  .icono-4,
  .icono-5 {
    padding-top: 45px;
    font-size: 12px !important; //sass-lint:disable-line no-important
    line-height: 22px; }

  .icono-6 {
    padding-top: 45px;
    font-size: 12px;
    line-height: 22px;

    span {
      font-size: 12px; } }

  .navbar-default {
    .navbar-nav {
      > li {
        > a {
          font-size: 15px;
          line-height: 25px; } } }

    .navbar-collapse {
      position: relative;
      z-index: 999; } }

  .footer {
    a {
      margin: 15px auto 0; }

    p {
      text-align: center; } }

  .container-comoseusa {
    .player {
      width: 250px;
      height: 200px;
      margin: 5px auto; } } }

//////////////////
// IPAD portrait
//////////////////

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .navbar-default {
    .navbar-nav {
      > li {
        > a {
          padding: 0 5px;
          font-size: 7px; } } } } }

//////////////////
// IPAD landscape
//////////////////

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .navbar-default {
    .navbar-nav {
      > li {
        > a {
          padding: 0 10px;
          font-size: 10px; } } } }

  li {
    sup {
      font-size: 11px; } } }

@media (max-width: 991px) {
  .container {
    >.navbar-header {
      margin-right: 0;
      float: none; } }

  .navbar-toggle {
    display: block; }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba($white, .1); }

  .navbar-collapse {
    &.collapse {
      display: none !important; } } //sass-lint:disable-line no-important

  .navbar-nav {
    margin: 0 !important; //sass-lint:disable-line no-important
    float: none !important; } //sass-lint:disable-line no-important

  .navbar-nav {
    >li {
      display: block !important; //sass-lint:disable-line no-important
      margin-left: 0 !important; //sass-lint:disable-line no-important
      border-bottom: 1px solid $gainsboro;
      border-radius: 0 !important; //sass-lint:disable-line no-important

      &:after {
        display: none; }

      &:nth-last-of-type(2),
      &:last-of-type {
        border-bottom: 0; } } }

  .navbar-nav {
    >li {
      >a {
        padding: 15px 30px !important; //sass-lint:disable-line no-important

        br {
          display: none; } } } }

  .navbar-text {
    margin: 15px 0;
    float: none; }

  .navbar-collapse {
    &.collapse {
      &.in {
        display: block !important; //sass-lint:disable-line no-important
        padding: 0; } } }

  .collapsing {
    overflow: hidden !important; } } //sass-lint:disable-line no-important

@media (max-width: 1200px) {
  .navbar-default {
    .navbar-nav {
      >li {
        >a {
          padding: {
            right: 20px;
            left: 20px; } } } } } }
