$cobalt: #005cae;
$green: #0e9941;
$eucalyptus: #30a558;
$black: #000;
$night-rider: #333;
$matterhorn: #4d4d4d;
$dim-gray: #666;
$grey: #757575;
$nobel: #999;
$silver: #bdbdbd;
$gainsboro: #e0e0e0;
$white: #fff;
$dark-cerulean: #004587;
