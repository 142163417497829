@font-face {
  font-family: "IconFont";
  src: url('../fonts/IconFont.eot');
  src: url('../fonts/IconFont.eot?#iefix') format('eot'),
  url('../fonts/IconFont.ttf') format('truetype');
}

@mixin icon-styles {
  font-family: "IconFont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == pointer {
  $char: "\E001";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon;
  } @else {
    @include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon-pointer {
  @include icon(pointer);
}
